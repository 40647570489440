.role-management-tool-card {
    width: 20%;
    left: 40%;
    z-index: 999;
    height: 15vh;
    position: absolute;
    padding: 16px;
    font-family: Arial, Helvetica, sans-serif;
    box-shadow:  0 19px 38px #00000042;
    top: 5vh;
    min-width: 300px;
}

.role-management-tool-option {
    padding-left: 25px;
}