.dialog-content {
    font-family: TheSansC4s !important;
    line-height: 1.5rem;
    letter-spacing: .03125em;

    .full-span>div {
        width: calc(100% - 16px);
        margin: 8px;
    }

    .split-span>div {
        width: calc(50%);
        float: left;
    }

}

.MuiDialogActions-root .MuiButton-root + .MuiButton-root {
    // This style does come from the Orbit component itself,
    // but a style in hybrid-fixes.scss overrides it with !important,
    // that's why this !important application is required here
    margin-left: 16px !important;
}