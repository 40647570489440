@use '../../colors';

#cable-info {
    .generate {
        width: 110px;
    }

    .progressbar-container {
        overflow-x: hidden;
        height: 4px;
        margin: -4px -24px 4px -24px;

        .bar {
            height: 4px;
            background-color: rgba(0, 153, 219, 0.2);
            position: relative;

            .slider {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                will-change: left, right;
                background-color: colors.$secondary;
                animation: slide 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
            }
        }
    }
}

.design-area-color-selector {
    position: absolute;
    top: 50%;
    transform: translate(-53%, -46%);
    right: 24px;
    z-index: 10;
    width: 320px;
    background-color: #E1F5FC;

    .color-selector-content {
        display: flex;
        flex-wrap: wrap;

        &>div {
            width: calc(16.66% - 16px);
            margin: 8px;
        }

        .color-item {
            height: 32px;
            width: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            border: 2px solid #707070;
        }
    }
}

.design-area-color {
    width: 24px;
    height: 24px;
    border: 2px solid #4E4E50;
    border-radius: 50%;
}

.card-divider {
    height: 1px;
    width: 100%;
    background-color: rgba(#757575, 0.40);
}

@keyframes slide {
    0% {
        left: -35%;
        right: 100%;
    }

    60% {
        left: 100%;
        right: -90%;
    }

    100% {
        left: 100%;
        right: -90%;
    }
}

.recommendation {
    margin-top: 4px;
    border-top: solid 1px #4e4e5033;
    padding-top: 7px;
    padding-left: 17px;
    padding-right: 16px;
    padding-bottom: 2px;
    font-family: TheSansC4s, Arial, Helvetica, sans-serif;
    color: #4E4E50;

    span {
        font-size: 16px;
        font-weight: bold;
    }

    p {
        margin-top: 11px;
        font-size: 14px;
        font-weight: 300;
    }
}

Button.card-icon-button {
    min-width: unset;
}

.create-build-content {
    padding: 0rem 1rem;

    .selectors {
        display: flex;
        flex-direction: column;
    }

    .cable {
        margin-bottom: 15px;
    }

    .spans {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .span-list {
            max-height: 18.75rem;
            width: 100%;
            margin: 10px 16px 0px 0px;
            min-height: 3rem;
            padding-right: 0px;
            transition: min-height 1s ease;

            &.scroll {
                overflow-y: scroll;
            }

            &:hover {
                min-height: 6rem;
            }

            .span-item {
                padding-left: 0.8rem;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                text-align: left;

                &.new-span {
                    border-top: #7f7f979e 1px solid;
                    padding-top: 0.7rem;
                    min-height: 4rem;
                }

                .item-value {
                    color: rgba(33, 33, 33, 0.6);
                }

                button {
                    visibility: hidden;
                }

                &:hover {
                    button {
                        visibility: initial;
                    }
                }
            }
        }

        .span-enter {
            padding-top: 0.5rem;
            padding-left: 0.8rem;
        }

        .form-row-field {
            display: flex;
            flex-direction: row;
            border-radius: 4px;
            align-items: baseline;

            .prop-label {
                width: auto;
                min-width: 70px;
            }
        }

    }
}