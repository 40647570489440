@use "../../../colors";
@use "../../../fonts";

.import-card-wrapper {
    background-color: white;
    width: 960px;
    position: absolute;
    right: 0;
    max-height: calc(100% - 78px);
    margin-top: 78px;
    margin-right: 4px;
    padding-right: 6px;
    overflow-y: scroll;
    background-color: transparent;

    .import-card {
        border: none;
        border-radius: 4px;
        background-color: colors.$surface;
        margin-bottom: 16px;
        width: unset;

        .import-card-header {
            height: 36px;
            line-height: 36px;
            cursor: unset;
            margin: 0 16px;
        }

        .format-selection {
            margin-bottom: 8px;
            p {
                padding: 10px 10px 0 0;
            }
        }

        .import-card-content {
            margin: 0 16px;
        }

        .import-card-footer {
            padding: 24px 0;
            display: flex;
            flex-direction: row;

            .push-right {
                margin-left: auto;
            }

            .positive-button {
                padding: 0.75rem;
            }

            .negative-button {
                padding: 0.7rem;
                margin-left: auto;
                background: transparent;
                color: colors.$error;
                margin-right: 0.5rem !important;
            }

            .negative-button:hover {
                background: rgba(158, 158, 158, 0.5);
            }

            .positive-button-loading {
                color: colors.$secondary;
                height: 20px;
                width: 20px;
            }
        }
    }
}