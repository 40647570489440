@font-face {
    font-family: TheSansC4s;
    src: url('./fonts/the-sans-c4s/TheSansC4s-2_ExtraLight.otf');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: TheSansC4s;
    src: url('./fonts/the-sans-c4s/TheSansC4s-2iExtraLightIta.otf');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: TheSansC4s;
    src: url('./fonts/the-sans-c4s/TheSansC4s-3_Light.otf');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: TheSansC4s;
    src: url('./fonts/the-sans-c4s/TheSansC4s-3iLightItalic.otf');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: TheSansC4s;
    src: url('./fonts/the-sans-c4s/TheSansC4s-4_SemiLight.otf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: TheSansC4s;
    src: url('./fonts/the-sans-c4s/TheSansC4s-4iSemiLightIta.otf');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: TheSansC4s;
    src: url('./fonts/the-sans-c4s/TheSansC4s-5_Plain.otf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: TheSansC4s;
    src: url('./fonts/the-sans-c4s/TheSansC4s-5iPlainItalic.otf');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: TheSansC4s;
    src: url('./fonts/the-sans-c4s/TheSansC4s-6_SemiBold.otf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: TheSansC4s;
    src: url('./fonts/the-sans-c4s/TheSansC4s-6iSemiBoldIta.otf');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: TheSansC4s;
    src: url('./fonts/the-sans-c4s/TheSansC4s-7_Bold.otf');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: TheSansC4s;
    src: url('./fonts/the-sans-c4s/TheSansC4s-7iBoldItalic.otf');
    font-weight: 700;
    font-style: italic;
}

$headline: 200 24px TheSansC4s; // TheSans, Light, 24
$title: bold 20px TheSansC4s; // TheSans, Bold, 20, Title Case
$subheader: 16px TheSansC4s; // TheSans, Plain, 16
$subsubheader: 10px TheSansC4s; // TheSans, Plain, 10
$menuheader: bold 16px TheSansC4s; // TheSans, Bold, 16
$menubody: bold 14px TheSansC4s; // TheSans, Bold, 14
$body: 300 14px TheSansC4s; // TheSans, SemiLight, 14
$caption: 300 12px TheSansC4s; // TheSans, SemiLight, 12
$button: bold 14px TheSansC4s; // TheSans, Bold, 14, Upper Case
$statustext: 600 12px TheSansC4s; // TheSans, SemiBold, 12
$tablecell: 14px TheSansC4s; // TheSans, Plain, 14
$tableheadercell: bold 14px TheSansC4s; // TheSans, Bold, 14
$toast: 500 16px TheSansC4s; // TheSans, Bold, 20
$reviewtitle: 500 14px TheSansC4s; // TheSans, Semisemibold, 14
$body2: 14px TheSansC4s; // TheSans, Plain, 14
$reviewmessage: 12px TheSansC4s; // TheSans, Plain, 12
