@use "../../colors";
@use 'sass:math';

$offset: 100;
$duration: 1.4s;

.dna-spinner {
    animation: rotator $duration linear infinite;

    .path {
        stroke-dasharray: $offset;
        stroke-dashoffset: 0;
        transform-origin: center;
        stroke: colors.$secondary;
        animation: dash $duration ease-in-out infinite;
    }
}

@keyframes rotator {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(270deg);
    }
}

@keyframes dash {
    0% {
        stroke-dashoffset: $offset;
    }

    50% {
        stroke-dashoffset: math.div($offset, 4);
        transform: rotate(135deg);
    }

    100% {
        stroke-dashoffset: $offset;
        transform: rotate(450deg);
    }
}