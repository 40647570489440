@use '../../colors';

.nap-card {
    .card-button-container {
        margin-left: -12px;
        margin-right: -12px;
    }
}

.nap-card-diagram {
    .nap-diagram {
        margin-left: -24px !important;

        .btn-add-delete {
            position: absolute;
            top: 100px;
            left: 0px;
            background: transparent;
            width: 50px;
            
            .btn-add-tether {
                circle {
                    fill: colors.$secondary;
                }

                .btn-add-cross {
                    fill: white;
                    stroke-width: 2;
                    opacity: 0.8;
                }

                cursor: pointer;
            }

            .btn-delete-tether {
                circle {
                    fill: #F32D16;
                }

                .trashcan {
                    fill: white;
                    opacity: 0.8;
                }

                cursor: pointer;
            }

            .btn-add-tether, .btn-delete-tether {
                &.disabled {
                    circle {
                        fill: colors.$disabled;
                    }
                }
            }
        }
    }
}

.nap-diagram-children {
    .header-with-button {
        margin-top: 16px;
        display: flex;

        .icon-button {
            margin-left: auto;
        }
    }

    .collapsed {
        display: none;
    }

    &.no-header {
        margin-top: 8px;

        .card-content {
            margin-top: 8px;
        }
    }
}

button.preterm-config {
    float: right;

    svg {
        margin-right: 8px;
    }

    &:not(:disabled) {
        background: colors.$secondary !important;
        color: colors.$on-secondary !important;
    }

    &:hover {
        background: rgba($color: colors.$secondary, $alpha: 0.8) !important;
    }
}

div.preterm-config {
    height: 403px;
    margin: 0 -24px;
    padding: 0 12px;
    border-top: 1px solid rgba($color: colors.$on-surface, $alpha: 0.2);
    border-bottom: 1px solid rgba($color: colors.$on-surface, $alpha: 0.2);

    .menuheader {
        color: colors.$on-surface;
    }
}

.preterm-config-row {
    padding: 0 8px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba($color: colors.$on-success, $alpha: 0.8);
    border-radius: 4px;
    margin: 8px 0;

    .caption {
        color: rgba($color: colors.$drawer-on-primary, $alpha: 0.6);
    }
}

.card-separator {
    width: 352px;
    height: 1px;
    background: colors.$on-surface 0% 0% no-repeat padding-box;
    opacity: 0.2;
    margin-left: -16px;
}