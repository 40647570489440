@use "../../colors";
@use "../../fonts";

.text-common > * {
    letter-spacing: 0px !important;
    border-color: rgba(78, 78, 80, 0.6) !important;
    font-family: TheSansC4s !important;
}

.oss-component {
    color: colors.$secondary;
}

.text-head > * {
    font-size: 16px !important;
    font-weight: bold !important;
    padding-top: 12px !important;
}

.width-component {
    width: 30%;
}

.width-version {
    width: 20%;
}

.width-disclaimer {
    width: 50%;
}

.dialog-tdp {
    [role=dialog] {
        max-width: 900px;
    }

    table {
        table-layout: fixed;
    }

    thead {
        background-color: colors.$card-background;
        box-shadow: 0 2px 0 rgba(78, 78, 80, 0.6);
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        
        th {
            border: none;
        }
    }
    tbody {
        tr td:first-child {
            word-wrap: break-word;
        }
        tr:first-child td {
            border-top: none;
        }
        tr:last-child td {
            border-bottom: none;
        }
    }
}

