.build-actions-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 8px 8px;

    .MuiButton-root {
        width: 100%;
        justify-content: flex-start;
    }
}