@use "../../fonts";
@use "../../colors";
@use "../../dna.scss";

.card-container {
    @extend .tool-overlay-container;
    overflow: visible;
    border: none;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: max-content;
}

.card {
    padding: 1rem;
    width: 320px;

    &.collapsed .card-content {
        display: none;
    }

    .edit-text-container {
        width: 100%;
    }

    .card-select {
        width: 100%;
        margin: 0.5rem 0;
    }

    &#canvas-design-area-card .MuiInputBase-formControl {
        height: 3.5rem;
    }

    .extra-footer-content {
        margin-top: 10px;
    }
}

.card-title,
.card-header {
    cursor: pointer;
    margin-bottom: 16px;

    .card-subheader {
        font: fonts.$body;
        margin-top: 2px;
        text-transform: uppercase;
    }
}

.card-collapsible {
    border-top: 1px solid rgba(#101010, 0.4);

    .collapsible-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .collapsible-content {
        &.collapsed {
            display: none;
        }

        .card-footer {
            padding-bottom: 8px;
            margin: 0 -8px;

            .mdc-button__label {
                letter-spacing: 0.2px;
            }
        }

        .caption {
            margin-bottom: 10px;
        }
    }
}

.card-controls {
    top: 16px;
    right: 16px;
}

.card-header {
    display: flex;
    flex-grow: 1;

    img {
        height: 40px;
        width: 40px;
        margin-right: 16px;
    }

    div {
        display: flex;
        flex-direction: column;
        max-width: 80%;
    }

    p {
        text-overflow: ellipsis;
        overflow: hidden;
        margin: 0;
    }

    .icon-border {
        border-radius: 50%;
        border: 4px solid #FFFFFF;
        box-shadow: 0px 0px 6px #00000029;
        opacity: 1;
    }
}

.card-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;

    &.align-left {
        justify-content: left;
        column-gap: 1rem;
    }

    &.align-right {
        justify-content: right;
        column-gap: 1rem;
    }

    .negative {
        margin-right: 0px !important;
    }

    .positive-button {
        background: colors.$secondary;
        color: colors.$on-secondary;
    }

    .footer-menu {
        display: flex;
        flex-direction: row;
        column-gap: 1rem;
    }

    button {
        min-width: unset;
    }
}

.card-content {
    margin-left: 8px;
    margin-right: 8px;

    button {
        margin: 0 2px;
    }

    .card-select,
    .card-input {
        width: 94%;
        margin-bottom: 8px;
    }

    .spinner {
        text-align: center;
    }
}

.card-button-container {
    margin-top: 6px;
}

.card-input {
    background-color: transparent !important;

    input {
        background-color: rgba(colors.$on-surface, 0.04) !important;
    }
}

.max-characters {
    color: colors.$error !important;
}

.card-select {
    >div {
        background-color: transparent !important;

        >i {
            // need to do this because by default it uses the color #6200ee when clicked on
            background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='10' height='5' viewBox='7 10 10 5' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' opacity='.54' d='M7 10l5 5 5-5z'/%3E%3C/svg%3E") no-repeat 50% !important;
        }
    }

    select {
        background-color: rgba(colors.$on-surface, 0.04) !important;
    }
}

.split-item {
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    text-align: left;
    vertical-align: middle;
    justify-content: space-between;
    margin-bottom: 10px;

    .item-name {
        margin-bottom: 4px;
    }

    .item-value {
        color: rgba(33, 33, 33, 0.6);
        max-width: 6rem;
        padding-right: 4rem;

        &.editable {
            cursor: pointer;
            color: colors.$secondary;

            &:hover {
                text-decoration: underline;
            }
        }

        &.disabled {
            color: colors.$disabled;
        }
    }

    .card-select {
        width: 95%;

        .mdc-select {
            margin-bottom: 0;
        }
    }

    .item-edit {
        display: none;
        position: absolute;
        align-self: center;
        right: 16px;

        img {
            width: 12px;
            height: 12px;
        }
    }

    &:hover {
        .item-edit {
            display: block;
        }
    }

    &.extra-info {
        border-radius: 0;
        border-top: 1px solid rgba(colors.$on-primary, 0.2);
        color: colors.$on-surface;
        font-size: 12px;
        font-weight: 300;
        padding-top: 10px;
    }
}

.split-item input {
    width: 50px;
}

.switch-container {
    margin-left: 8px;
    margin-bottom: 8px;

    .switch-option {
        margin-left: 16px;
    }
}

.slider-container {
    margin: 8px;
    margin-top: 16px;

    .MuiSlider-markLabel {
        color: colors.$on-surface !important;
        font: fonts.$caption;
        text-align: center;
    }

    .MuiSlider-root {
        color: colors.$secondary !important;

        &.Mui-disabled {
            color: colors.$disabled !important;
        }
    }

    .MuiSlider-rail {
        color: rgba(0, 0, 0, 0.26);
    }
}