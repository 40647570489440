
.tap-card-diagram {
    margin-bottom: -6px;
    .tap-diagram {
        margin-left: -24px !important;
        margin-bottom: 8px;
    }
}
.tap-diagram-children {
    .header-with-button {
        margin-top: 16px;
        display: flex;
        
        .icon-button {
            margin-left: auto;
        }
    }
}
