@use "../../../../colors";
@use "../../../../fonts";

.dialog-content {

    &.import-shape-file-dialog-content {

        & .header {
            display: flex;
            margin-bottom: 1rem;
            padding: 1.5rem 0.5rem;
            border-top: 1px solid colors.$disabled;
            border-bottom: 1px solid colors.$disabled;

            & .step {
                display: flex;
                align-items: flex-start;
                text-align: center;

                &.disabled {
                    color: colors.$disabled;
                }

                & .step-index {
                    background-color: colors.$secondary;
                    border-radius: 50%;
                    padding: 5px;
                    color: colors.$on-secondary;
                    height: 16px;
                    width: 16px;
                    text-align: center;
                    line-height: 16px;
                    margin: 0 0.5rem 0 0;

                    &.disabled {
                        background-color: colors.$disabled;
                    }
                }

                &:after {
                    content: '';
                    flex-grow: 1;
                    height: 1px;
                    background: colors.$disabled;
                    width: 50px;
                    margin: auto 10px;
                }
            }
        }

        & .content {
            & .log {
                min-height: 5rem;
                border: 1px solid colors.$disabled;
                padding: 0.75rem 0.75rem;
                background-color: white;

                & ul {
                    padding: 0 0.5rem;
                    margin: 0;
                }
            }
        }
    }
}