@use "../../../colors";

.progress-container {
    margin-top: 16px;
    height: 88px;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: hidden;
    overflow-y: hidden;
    white-space: nowrap;
    background-color: rgba(colors.$secondary, 0.7);
    border-radius: 4px;
    padding: 16px;
    cursor: pointer;
    
    .indicator-progress-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        
        img {
            margin-right: 16px;
        }
    }
    
    .indicator-container {
        display: flex;
        flex-direction: column;
        margin-right: 16px;
        cursor: pointer;

        label {
            white-space: nowrap;

            &.subheader {
                cursor: pointer;
                color: rgba(white, 0.8);

                &.selected {
                    color: white;
                }
            }

            &:empty:before {
                content: "\200b";
            }

            &.caption {
                cursor: pointer;
                color: rgba(#191919, 0.8);
            }
        }

        &:hover {
            label {
                &.subheader {
                    color: white;
                }
    
                &.caption {
                    color: #191919;
                }
            }
        }
    }
}
