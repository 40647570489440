@use "../../../colors";
@use "../../../fonts";

.preset-config {
    position: relative;
    flex-basis: 60%;
    margin: 0px 16px 16px 8px;
    overflow-y: overlay;

    .empty-preset-container {
        padding: 16px;
        border-radius: 8px;
        background-color: #D7D2CB;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;

        .title {
            color: colors.$error;
            padding-bottom: 8px;
        }

        .underline {
            text-decoration: underline;
            cursor: pointer;
            text-transform: uppercase;
        }
    }

    .card {
        padding: 16px;
        background-color: #E1F5FC;

        .card-content {
            margin: 0;

            .radio-group-container {
                .radio-group-subheader {
                    display: flex;
                    justify-content: space-between;
                    padding: 20px 0 0 5px;
                }

                .subheader {
                    margin-left: 0;
                }

                .radio-group {
                    margin-left: 0;
                }
            }

            > div {
                margin-top: 16px;
            }
        }

        .link {
            color: colors.$secondary;
            background-color: transparent;
            text-decoration: underline;
            cursor: pointer;
        }

        .save-button {
            margin-left: 8px;
            padding-left: 16px;
            padding-right: 16px;
            height: 48px;
            border-radius: 30px;
            background: colors.$secondary;
            color: white;
        }

        .divider {
            height: 1px;
            background-color: rgba(#101010, 0.2);
            margin-bottom: 8px;
        }

        .edit-text-container .text-field-container .mdc-text-field { /* override style to match card inputs */
            &:not(.mdc-text-field--disabled) {
                background-color: unset;
            }
            
            input {
                background-color: rgba(78, 78, 80, 0.04);
            }
        }

        .progress-container {
            padding-top: 0;
            padding-bottom: 0;
        }

        &.properties {
            .preset-icon {
                width: 40px;
                height: 40px;
                clip-path: circle(19px at center);
            }

            .description {
                font: fonts.$body;
                color: black;
            }

            .body {
                color: rgba(0, 0, 0, 0.54);
            }

            .fiber-count {
                color: #2CB4EC;
            }

            .cable-properties {
                display: flex;
                padding-top: 10px;

                .preset-property-header {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .default {
                        color: #4D8809;
                        background-color: rgba(#4D8809, 0.12);
                    }
                }

                .cable-summary {
                    width: 100%;
                    margin-left: 16px;

                    .subtitle {
                        font-size: 16px;
                    }
                }
            }

            .attributes {
                display: flex;
                flex-wrap: wrap;
                margin: 8px 0px;
                
                .attribute {
                    flex-basis: 33%;
                    margin: 8px 0;
                    display: flex;
                    flex-direction: column;

                    .attribute-title {
                        font: fonts.$caption;
                        color: rgba(#191919, 0.6);
                    }

                    .attribute-value {
                        font: fonts.$body;
                        color: #101010;
                        margin-top: 4px;
                    }
                }
            }
        }

        .properties-footer {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .switch-container {
                margin: 0px !important;
            }
        }
    }

    .card .properties {
        padding: 0 !important;
    }

    .mdc-select {
        width: 100%;
        margin-top: 4px;
        margin-bottom: 4px;
    }

    .cable-property-message {
        display: flex;
        padding: 16px;
        width: calc(100% - 42px);
        align-items: center;
        justify-content: center;
        margin-top: 32px;
        background-color: rgba(colors.$secondary, 0.16);
    }

    .part-selection {

        .navigation-container {
            display: flex;
            flex-direction: row;
            justify-content: right;
            align-items: center;
            width: 100%;
            padding-top: 16px;
        }
    }

    .fiber-count-container {

        .cable-values,
        .tether-values {
            margin-top: 16px;
        }
    }

    .dna-spinner-container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 16px;
    }
}