@use '../../colors';

.forbidden-access-panel {
    background-color: colors.$error;
    color: colors.$on-error;
    width: 376px;
    padding: 24px 24px 24px 24px;
    left: 50%;
    transform: translateX(-50%);
    top: 10vh;
    z-index: 300;
    position: absolute;
    box-shadow:  0 19px 38px #00000042;
    border-radius: 4px;
    display: flex;
}

.forbidden-access-image {
    max-height: 80px;
    max-width: 80px;
    margin-right: 16px;
}