@use "../../colors";

.right-side-drawer {
    position: absolute;
    right: 0;
    max-height: calc(100% - 78px);
    margin-top: 78px;
    margin-right: 4px;
    padding-right: 6px;
    padding-left: 16px;
    overflow-y: scroll;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.left-side-drawer {
    position: absolute;
    z-index: 200;
    height: 100%;
    width: 408px;
    background-color: rgba($color: colors.$card-background, $alpha: 0.5);
    backdrop-filter: blur(30px);
    border: none;
    display: none;
    overflow-y: auto;

    &.open {
        display: block;
    }

    .drawer-header {
        display: block;

        .options {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 16px;
            background-color: colors.$card-background;
            box-shadow: 0px 2px 6px rgba(black, 0.16);

            .options-menu {
                z-index: 1;
                top: unset !important;
                left: unset !important;
                right: 16px;

                .MuiList-root {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 8px 8px;

                    .MuiButton-root {
                        width: 100%;
                        justify-content: flex-start;
                    }

                    .MuiButton-root + .MuiButton-root {
                        margin-top: 8px !important;
                    }
                }
            }

            .button-group-button {
                border: 1px solid colors.$secondary;
                color: colors.$secondary;

                &:disabled {
                    border: 1px solid #0000001f;
                }
            }
        }
    }

    .divider {
        height: 1px;
        background-color: rgba(33, 33, 33, 0.3);
    }

    .debugmenu {
        display: flex;
        flex-direction: column;
    }

    button.add-design-area {
        height: 2.25rem;
    }
}
