@use '../../../colors';

.card-content {
    &.inline {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    
    .MuiButtonGroup-root {
        .button-group-button {
            border: 1px solid colors.$secondary;
            color: colors.$secondary;
        
            &:disabled {
                opacity: 1;
                border: 1px solid colors.$secondary;
                color: colors.$secondary !important;
                background-color: colors.$active-button !important;
            }
        }
    }
}