@use "../../../../colors";
@use "../../../../fonts";

.dialog-content {

    &.import-geojson-file-dialog-content {
        & .header {
            display: flex;
            margin-bottom: 1rem;
            padding: 1.5rem 0.5rem;
            border-top: 1px solid colors.$disabled;
            border-bottom: 1px solid colors.$disabled;

            & .step {
                display: flex;
                align-items: flex-start;
                text-align: center;

                &.disabled {
                    color: colors.$disabled;
                }

                & .step-index {
                    background-color: colors.$secondary;
                    border-radius: 50%;
                    padding: 5px;
                    color: colors.$on-secondary;
                    height: 16px;
                    width: 16px;
                    text-align: center;
                    line-height: 16px;
                    margin: 0 0.5rem 0 0;

                    &.disabled {
                        background-color: colors.$disabled;
                    }
                }

                &:after {
                    content: '';
                    flex-grow: 1;
                    height: 1px;
                    background: colors.$disabled;
                    width: 71px;
                    margin: auto 10px;
                }

                &:last-child:after {
                    content: none;
                    flex-grow: 0;
                    height: 0;
                    width: 0;
                    margin: 0;
                }
            }
        }

        & .content {

            & .build-list {
                height: 25rem;

                & p {
                    margin-bottom: 1.5rem;
                }

                .build-list-table-cell {
                    font: fonts.$tablecell;

                    &.table-column {
                        font: fonts.$tableheadercell;
                        color: colors.$on-surface-integrated;

                        &.presets .MuiTypography-root {
                            margin: 0;
                            font: fonts.$tableheadercell !important;
                            color: colors.$on-surface-integrated !important;
                        }
                    }
                }

                & .table-container {
                    max-height: 25rem;
                    background-color: #fff;

                    & table {
                        width: 100%;
                    }

                    .orbit-text-field-container .MuiTextField-root {
                        height: unset;
                    }
                }


                tbody {
                    tr {

                        &.build-list-item-errors {
                            td {
                                padding: 0 20px;

                                .build-errors {
                                    background-color: colors.$on-surface-bg;
                                    border-radius: 8px;
                                    padding: 20px;

                                    .build-list-item-error {
                                        margin-top: 0.8em;

                                        &:first-child {
                                            margin-top: 0;
                                        }

                                        strong {
                                            color: colors.$error;
                                            margin-right: 0.5em;
                                        }
                                    }
                                }
                            }
                        }

                        &.collapsed {
                            display: none;
                        }

                        td {
                            border: none;
                        }
                    }
                }

                .table-column {
                    &.build-id {
                        width: 15%;
                    }

                    &.icon,
                    &.checkbox {
                        text-align: center;
                        width: 5%;
                    }

                    &.counts {
                        width: 15%;
                    }

                    &.presets {
                        width: 25%;

                        .split-item {
                            margin: 0;
                            padding: 0;

                            .item-name {
                                margin: 0;
                            }

                            .item-value {
                                text-align: right;
                                margin: 0;
                            }
                        }

                        .card-select {
                            margin: 0;
                            width: 100%;
                        }
                    }
                }

                .build-list-item {
                    td {
                        vertical-align: middle;
                    }

                    .icon {
                        .build-import-icon {
                            width: 100%;
                            height: 100%;

                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }
            }

            & .log {
                min-height: 25rem;
                border: 1px solid colors.$disabled;
                padding: 0.75rem 0.75rem;
                background-color: colors.$on-input;

                & ul {
                    padding: 0 1rem;
                    margin: 0;
                }
            }
        }
    }
}