@use '../../../../../../fonts';
@use '../../../../../../colors';
@use '../../../../../../sizes';
@use 'sass:math';

.build-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    min-height: 40px;
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 32px;
    padding-right: 16px;

    &.exported {
        opacity: 0.8;
    }

    &.locked {
        background-color: rgba($color: colors.$disabled-text, $alpha: 0.3);
    }

    &.selected {
        background: rgba($color: colors.$secondary, $alpha: 0.3);
    }

    &:hover {
        background-color: rgba($color: colors.$on-secondary, $alpha: 0.8);
    }

    .build-icon {
        margin-top: math.div(sizes.$btn-square-width-default, 4);

        .hover-build-icon-path {
            display: none;
        }

        &:hover {
            cursor: pointer;

            .build-icon-path {
                display: none;
            }

            .hover-build-icon-path {
                display: inline;
            }
        }
    }

    .build-row-end {
        width: 100%;
        padding-left: 16px;

        .build-info-container {
            display: flex;
            flex-direction: column;

            .row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding-top: 0;

                .build-id {
                    font: fonts.$subheader;
                    color: colors.$on-surface;
                }

                .build-last-modified {
                    font: fonts.$caption;
                    color: rgba($color: colors.$drawer-on-primary, $alpha: 0.6);
                }
            }
        }
    }
}