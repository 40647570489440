@use "../../colors" as colors;
@use "../../fonts" as fonts;

.preset {
    position: absolute;
    background-color: rgba(colors.$on-primary, 0.6);
    z-index: 100;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    
    &.open {
        display: block;
    }

    &.closed {
        display: none;
    }

    .preset-panel {
        position: absolute;
        display: flex;
        flex-direction: column;
        width: 66%;
        min-width: 800px;
        height: 100%;
        top: 0px;
        right: 0px;
        background-color: #efedeb;
        overflow-y: overlay;
        z-index: 100;

        .preset-header {
            padding-top: 16px;
            padding-left: 16px;
            padding-right: 16px;

            .title {
                font-weight: normal;
                text-transform: uppercase;
            }
        }

        .presets {
            position: relative;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            height: 100%;
            overflow: hidden;
        }
    }

    .navigation-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-top: 16px;

        .continue-btn {
            margin-left: auto !important;
        }
    }

    .card {
        margin-bottom: 20px;
        width: unset;

        .primary-title {
            font: fonts.$title;
        }

        .secondary-title {
            font: fonts.$subheader;
        }

        .guide-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;
            padding-top: 16px;

            .prompt {
                max-width: 75%;
            }
        }
    }
}