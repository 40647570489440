#header.clearfix > *, #nav_main.nav_main > *, .yCmsContentSlot.footer > * {
    font-family: Arial, sans-serif !important;
    font-weight: inherit !important;
    font-style: inherit !important;
    font-size: 12px !important;
    #footer {
        font-weight: 400 !important;
        font-size: 10.8px !important;
    }
}

button.siteSearchSubmit {
    margin: 1px 1px 1px -30px !important;
}

body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, code, del, dfn, em, img, q, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, dialog, figure, footer, header, hgroup, nav, section {
        font-family: unset;
        font-weight: unset;
        font-style: unset;
        font-size: unset;
}

button, button img { // preset improper parent CSS selectors affecting the style of our application #
    margin: unset !important;
    padding: unset;
    border: unset;
    width: unset;
    height: unset;
    float: none;

    &:hover {
        color: unset;
        background-color: unset;
        border-color: unset;
    }

    &.negative, &.positive {
        height: unset;
        border-color: unset;
        background: unset;
    }
}
