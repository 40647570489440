@use '../../../colors';
@use '../../../fonts';

$subunit-sticky-row: #005692;

.splice-plan-dialog {
    .dialog-header {
        padding-bottom: 0 !important;
    }

    &-setup {
        font: fonts.$caption;
        display: flex;
        margin-bottom: 16px;

        &-open {
            padding-left: 8px;
            cursor: pointer;
            font: fonts.$caption !important;
            color: colors.$secondary;
            background-color: inherit;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &-table {
        .BaseTable__table-main,
        .BaseTable__empty-layer,
        .BaseTable {
            background-color: inherit;
            outline: inherit;
            box-shadow: inherit;
            max-width: 100%;
        }

        .BaseTable__empty-layer {
            display: flex;
            justify-content: center;
        }

        .BaseTable__body,
        .BaseTable__header {
            max-width: inherit;
            overflow-x: hidden !important;
        }

        .BaseTable__row,
        .BaseTable__header-row {
            font: fonts.$body;
            background-color: colors.$card-background;
            border-bottom: 1px solid rgba($color: colors.$on-surface, $alpha: 0.3);
        }

        .BaseTable__header-row {
            color: rgba($color: colors.$on-primary, $alpha: 0.5);
        }

        .BaseTable__row {
            color: rgba($color: colors.$on-primary, $alpha: 0.85);
        }

        .BaseTable__header-cell,
        .BaseTable__row-cell {
            padding: 16px;

            &:first-child {
                padding-left: 8px;
            }

            &:last-child {
                padding-right: 0;
            }
        }

        &-row {
            &.category {
                font-weight: 600;
            }
        }

        &-cell {
            &-column {
                display: flex;
                flex-direction: row;
                align-items: center;
            }
        }
    }

    &-pipe-color {
        width: 24px;
        height: 24px;
        border-radius: 100%;
        margin-right: 8px;
    }

    &-tube {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        &-fiber-internal,
        &-fiber-external {
            width: 8px;
            height: 8px;
            border-radius: 50%;
        }

        &-fiber-internal {
            background-color: white;
        }

        &-fiber-external {
            width: 5px;
        }
    }

    &-navigation {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin: 16px 0;

        &-content {
            display: flex;
        }

        &-button {
            display: flex;
            flex-shrink: 0;
            justify-content: center;
            align-items: center;
            width: 32px;
            height: 32px;
            margin: 0 4px !important;
            color: colors.$secondary;
            fill: colors.$secondary;
            background-color: transparent;
            border-radius: 2px;

            &:hover {
                background-color: rgba($color: colors.$disabled, $alpha: 0.1);
                color: colors.$secondary;
                cursor: pointer;
            }

            &:disabled {
                color: colors.$disabled;
                fill: colors.$disabled;
                pointer-events: none;
            }

            &.selected {
                background-color: rgba($color: colors.$secondary, $alpha: 0.2);
                cursor: default;
            }
        }
    }

    &-shortcuts {
        padding-bottom: 8px;
        text-align: center;
        font: fonts.$caption;
    }
}

.pipe-color {
    &-aqua {
        background-color: colors.$pipe-aqua;

        &.light {
            background-color: rgba($color: colors.$pipe-aqua, $alpha: 0.6);
            color: rgba($color: colors.$on-primary, $alpha: 0.85);
        }
    }

    &-black {
        background-color: colors.$pipe-black;

        &.light {
            background-color: rgba($color: colors.$pipe-black, $alpha: 0.6);
            color: rgba($color: colors.$on-error, $alpha: 0.85);
        }
    }

    &-blue {
        background-color: colors.$pipe-blue;

        &.light {
            background-color: rgba($color: colors.$pipe-blue, $alpha: 0.6);
            color: rgba($color: colors.$on-error, $alpha: 0.85);
        }
    }

    &-brown {
        background-color: colors.$pipe-brown;

        &.light {
            background-color: rgba($color: colors.$pipe-brown, $alpha: 0.6);
            color: rgba($color: colors.$on-error, $alpha: 0.85);
        }
    }

    &-green {
        background-color: colors.$pipe-green;

        &.light {
            background-color: rgba($color: colors.$pipe-green, $alpha: 0.6);
            color: rgba($color: colors.$on-primary, $alpha: 0.85);
        }
    }

    &-orange {
        background-color: colors.$pipe-orange;

        &.light {
            background-color: rgba($color: colors.$pipe-orange, $alpha: 0.6);
            color: rgba($color: colors.$on-primary, $alpha: 0.85);
        }
    }

    &-red {
        background-color: colors.$pipe-red;

        &.light {
            background-color: rgba($color: colors.$pipe-red, $alpha: 0.6);
            color: rgba($color: colors.$on-error, $alpha: 0.85);
        }
    }

    &-rose {
        background-color: colors.$pipe-rose;

        &.light {
            background-color: rgba($color: colors.$pipe-rose, $alpha: 0.6);
            color: rgba($color: colors.$on-primary, $alpha: 0.85);
        }
    }

    &-slate {
        background-color: colors.$pipe-slate;

        &.light {
            background-color: rgba($color: colors.$pipe-slate, $alpha: 0.6);
            color: rgba($color: colors.$on-error, $alpha: 0.85);
        }
    }

    &-violet {
        background-color: colors.$pipe-violet;

        &.light {
            background-color: rgba($color: colors.$pipe-violet, $alpha: 0.6);
            color: rgba($color: colors.$on-error, $alpha: 0.85);
        }
    }

    &-white {
        background-color: colors.$pipe-white;

        &.light {
            background-color: rgba($color: colors.$pipe-white, $alpha: 0.6);
            color: rgba($color: colors.$on-primary, $alpha: 0.85);
        }
    }

    &-yellow {
        background-color: colors.$pipe-yellow;

        &.light {
            background-color: rgba($color: colors.$pipe-yellow, $alpha: 0.6);
            color: rgba($color: colors.$on-primary, $alpha: 0.85);
        }
    }

    &-copper {
        background: linear-gradient(135deg, colors.$pipe-copper, colors.$pipe-copper-light, colors.$pipe-copper);
    }
}

.subunit-sticky-row {
    background-color: $subunit-sticky-row !important;
    color: rgba($color: colors.$on-secondary, $alpha: 0.85) !important;
}
