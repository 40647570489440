@import '~@orbit/inline-edit/build/index.css';

.inline-prop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    align-items: baseline;
    margin-bottom: 10px;

    .prop-label {
        margin-right: 1rem;
        white-space: nowrap;

        p {
            line-height: 2 !important;
        }
    }

    .inline-text-container,
    .inline-select-container {
        width: 60%;
        max-width: 60%;
    }

    .orbit-inline-select-container {

        // Correcting DNA overrides on list items
        ul.MuiList-root {
            background-color: unset;

            li.MuiMenuItem-root {
                padding: 6px 16px;
            }
        }

        ::-webkit-scrollbar {
            width: 8px;
            height: 8px;
        }

        ::-webkit-scrollbar-track {
            background: transparent;
        }

        ::-webkit-scrollbar-thumb {
            background: #005293;
            border-radius: 4px;
        }
    }
}