@use '../../../fonts';
@use '../../../colors';

#manual-port-number-content {
    width: 400px;
    display: flex;
    flex-direction: column;

    .MuiTypography-root {
        padding-left: 0.8rem;
    }
}

.selects-row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    .orbit-select {
        flex-grow: 1;
        padding: 0rem 0.5rem;
    }
}