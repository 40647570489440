.nap-card .nap-diagram-children.preterm {
    .radio-group-container {
        padding: 4px 8px;
        
        .subheader {
            margin-left: 0;
        }

        .radio-group label {
            min-width: 17px;
        }
    }
}