@use '../../../colors';

.workspace-drawer {
    &.mdc-drawer {
        top: 0;
        width: 408px;
        height: 100%;
        overflow: hidden;
        background-color: rgba($color: colors.$card-background, $alpha: 0.5);
        backdrop-filter: blur(30px);
        z-index: 200;
    }
}