@use '../../../../colors';
@use '../../../../fonts';

.workspace-drawer-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 72px;
    padding-left: 16px;
    padding-right: 16px;

    .workspace-drawer-header-title-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        .workspace-drawer-header-title {
            font: fonts.$headline;
            color: rgba($color: black, $alpha: 0.9);
            padding-left: 12px;
        }
    }
}