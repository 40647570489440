@use "../../colors";
@use "../../fonts";
@use "../../dna.scss";

.fullscreen-map, .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
}

.overlay > div {
    z-index: 3;
}

.ol-overlaycontainer-stopevent {
    @extend .tool-overlay-container;
    left: 128px;
    bottom: 16px;
    height: 32px !important;
    width: auto !important;
    display: flex;
    padding: 8px;

    .metric-scale {
        margin-left: 16px;
    }

    .metric-scale,
    .us-scale {
        border-bottom: 8px solid colors.$secondary !important;
        color: colors.$on-surface;
        font: fonts.$caption;
    }
    .us-scale-inner, .metric-scale-inner {
        margin-left: 8px;
    }
}

.layer-grayout {
    backdrop-filter: grayscale(100%);
}

.map-topbar {
    position: relative;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: colors.$surface;
    text-align: center;

    button {
        margin-left: 16px;
    }
    animation-duration: 2s;
    animation-name: makesense;
    animation-play-state: running;

    .exp-error {
        color: colors.$error;
    }
    .exp-warning {
        color: colors.$warning;
    }
    .exp-success {
        color: colors.$success;
    }
}

@keyframes makesense {
    from { 
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
