@use '../../../../fonts';
@use '../../../../colors';

.drawer-menu-body {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;

    .design-area-container {
        display: flex;
        flex-direction: column;
    }

    .unassigned-builds-container {
        display: flex;
        flex-direction: column;

        .unassigned-builds-title {
            padding: 8px 16px;
            font: fonts.$title;
            color: rgba($color: colors.$on-surface, $alpha: 0.6);
        }
    }

    .MuiDivider-root {
        background-color: rgba($color: colors.$on-surface, $alpha: 0.6);
    }

    .MuiDivider-middle {
        background-color: rgba($color: colors.$on-surface, $alpha: 0.2);
    }
}