@use '../../colors';

$disabled: #9E9E9E;
$disabledAddButton: #E0E0E0;
$disabledAddCross: #757575;
$enabled: #606060;
$hover: #005692;

$lightGray: #FAFAFA;
$darkGray: #606060;
$darkerGray: #4E4E50;

$optical: #38BCB8;
$power: #EB8D64;
$tetherOutline: #9E9E9E;

.tap-diagram svg {
    fill: $enabled;
    background: linear-gradient(0deg, #7FD4F7 0%, #E1F5FC 100%);
    overflow: visible;
}

.page-btn {
    cursor: pointer;
    fill: colors.$secondary;

    &.current {
        cursor: default;
        fill: white;
    }
}

.cable-subunit-power {
    fill:$power;
}

.cable-subunit-optical {
    fill:$optical;
}

.cable-gradient {
    mix-blend-mode: multiply;
    isolation: isolate;
    fill: url(#cableGradient);
}

.cable-subunit-outer {
    stroke:none;
    fill:#707070;
}

.btn-add-power,
.btn-add-optical {
    cursor: pointer;
    filter: url(#btnShadow);

    &:hover {
        opacity: 0.8;
    }
    
    &:active {
        opacity: 1;
        filter: url(#btnShadowClicked);
    }
}

.btn-add-power {
    fill: $power;
    &.disabled {
        fill: $disabledAddButton;
        cursor: default;
    }
}

.btn-add-optical {
    fill: $optical;
    &.disabled {
        fill: $disabledAddButton;
        cursor: default;
    }
}

.btn-add-cross {
    stroke: $lightGray;
    &.disabled {
        stroke: $disabledAddCross;
    }
}

.tether-counter {
    rect {
        stroke: none;
        fill: $darkerGray;
        fill-opacity: 0.2;
    }
    text {
        fill: $darkerGray;
        opacity: 0.8;
        font-family: TheSansC4s, Arial, sans-serif;
        font-size: 12px;
        font-weight: 350;
    }
}

.location-label {
    .container {
        fill: #4A4A4A;
        stroke: #707070;
        filter: url(#locationLabelShadow);
    }
    .fill {
        fill: none;
    }
    .outline {
        stroke: none;
    }
    text {
        fill: $lightGray;
        font-size: 10px;
        font-family: TheSansC4s, Arial, sans-serif;
        opacity: 0.6;
    }
}
