.splice-point-card {
    .card-header {
        text-transform: uppercase;
    }

    .splice-point-diagram {
        margin-left: -24px !important;
    }

    .card-button-container {
        margin-left: -12px;
        margin-right: -12px;
    }
}