@use "../../../colors";

.download-gis-data-dialog {
    .dialog-content {
        display: flex;
        flex-direction: column;
        padding-top: 0.5rem;
    }

    .download-design-area {
        margin-bottom: 1rem;
    }

    .download-layer-column-container {
        display: flex;
        flex-direction: row;
        margin-bottom: 1rem;
    }

    .download-layer-column {
        display: flex;
        flex-direction: column;
        margin-right: 1.5rem;

        &:last-child {
            margin-right: 0;
        }

        p {
            margin-bottom: 0.5rem;
        }
    }

    label {
        margin-left: 0;
    }

    .footnote {
        font-size: 0.75rem;
        margin-bottom: 1rem;
    }

    .summary-row-container {
        background-color: rgba(colors.$on-surface, 0.1);
        border-radius: 0.25rem;
        padding: 1rem;

        h2 {
            margin-top: 0;
            margin-bottom: 0.25rem;
            color: rgba(colors.$on-surface, 0.87);
            font-size: 1rem;
            font-weight: bold;
        }

        span {
            color: rgba(black, 0.87);
            font-size: 0.875rem;
            font-weight: 300;
        }
    }

    .summary-row {
        display: flex;
        column-gap: 4rem;
    }
}