@use '../../colors';

$light: #959595;
$dark: #606060;
$hover: #005692;

.nap-diagram svg {
    background: transparent linear-gradient(180deg, colors.$card-background 0%, #7FD4F7 100%) 0% 0% no-repeat padding-box;
    fill: $dark;
    overflow: visible;

    text {
        opacity: 0.6;
        stroke-width: 0;
        fill: white;
        text-anchor: middle;
        font-family: Arial;
    }
    .CO-nap{
        fill: colors.$on-surface;
        opacity: 0.8;
        font: normal normal bold 14px/20px TheSansC4s;
    }
    .tap,
    .tether {
        font-size: 12;
    }
    .terminal-box {
        font-size: 10px;
        rect {
            stroke-width: 1px;
        }
    }

    .cable {
        stroke: $dark;
    }

    .tether,
    .terminal {
        &.disconnected {
            path {
                stroke: $light;
            }
            rect {
                fill: $light;
            }
        }
    }
    .tap,
    .terminal-box {
        stroke-width: 2px;
        stroke: $light;
    }

    .tap {
        fill: $dark;
        &.disconnected {
            fill: $light;
        }
    }

    .tether {
        &.connected {
            path {
                stroke: $dark;
            }
            rect {
                fill: $dark;
            }
        }
        &.disconnected {
            path {
                stroke: $light;
            }
            rect {
                fill: $light;
            }
        }
        &.selected {
            path {
                stroke: colors.$secondary;
            }
            rect {
                fill: colors.$secondary;
            }
        }
        &:hover {
            path {
                stroke: $hover;
            }
            rect {
                fill: $hover;
            }
        }
    }
    .no-terminal {
        .no-terminal-label {
            font-size: 12px;
            text {
                fill: colors.$disabled-text;
            }
        }
    }

    .connector {
        stroke: colors.$on-secondary;
        stroke-width: 2px;
        opacity: 0.6;
        fill: none;
    }
    .fibercount {
        text {
            fill: white;
            font-size: 14px;
        }
        circle {
            fill: colors.$disabled-text;
            opacity: 0.5;
        }
    }

    .location-number {
        .location {
            fill: #4A4A4A;
            stroke: #707070;
            stroke-width: 1px;
        }
        text {
            fill: colors.$on-secondary;
            font-size: 10px;
            font-family: TheSansC4s, Arial, sans-serif;
            opacity: 0.6;
        }
    }

    .tether-terminal-container {
        .tether-terminal-backdrop {
            cursor: pointer;
            fill: colors.$on-secondary;
            opacity: 0;
        }

        .tether-terminal-children {
            pointer-events: none;
        }

        .tether-edit-icon {
            display: none;
            path {
                fill: colors.$on-secondary;
            }
            .tether {
                fill: colors.$secondary;
            }
        }

        &:hover {
            .tether-terminal-backdrop {
                fill: colors.$on-secondary;
                opacity: 0.4;
            }
            .tether-edit-icon {
                display: block;
            }
        }

        &.selected {
            .tether-terminal-backdrop {
                fill: colors.$secondary;
                opacity: 0.3;
            }
            .tether-edit-icon {
                display: none;
            }
        }
    }
}

