@use '../../../fonts';
@use '../../../colors';

.dialog.workspace-info {
    .dialog-content {
        width: 448px;
        min-width: 448px;
    }

    .workspace-info-container {
        display: flex;
        flex-direction: column;

        .workspace-info-helper-text {
            padding-bottom: 8px;
            font: fonts.$caption;
            color: rgba($color: colors.$on-surface, $alpha: 0.6);
        }

        .workspace-info-input-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 16px;

            .workspace-info-input-field {
                width: 200px;

                .edit-text-container {
                    margin: 0;
                }
            }

            .workspace-info-wide-input-field {
                width: 100%;
            }
        }

        .workspace-info-wide-input-field {
            padding-bottom: 10px;
        }

        .workspace-info-input-field {
            padding-bottom: 10px;
        }

        .workspace-info-cpq-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .workspace-info-input-field {
                width: 200px;
                padding-bottom: 0;
            }
        }

        .card-input {
            width: 100%;
        }

        .card-select {
            width: 100%;

            & > div {
                width: 100%;
            }
        }
    }
}