@use "../../../fonts";
@use "../../../colors";

.preset-row--selected,
.preset-row {
    width: inherit;
    display: flex;
    align-items: center;
    vertical-align: middle;
    padding: 16px 8px;
    cursor: pointer;
    flex-shrink: 0;

    label {
        cursor: pointer;
    }

    .preset-icon {
        width: 40px;
        height: 40px;
        margin-left: 8px;
        clip-path: circle(19px at center);
    }

    .preset-container {
        display: flex;
        flex-direction: column;
        margin: 0 16px;

        .caption {
            color: rgba(0, 0, 0, 0.54);
            font: fonts.$body;
        }
    }

    .default-icon {
        margin-left: auto;
        margin-right: 10px;
        width: 20px;
        height: 20px;
        color: colors.$success;
    }

    .set-as-default {
        margin-left: auto;
        margin-right: 8px;
        width: 16px;
        height: 16px;
        border: 2px solid #000000;
        border-radius: 50%;
        flex-shrink: 0;
    }
}

.preset-row:hover {
    background-color: rgba(224, 224, 224, 0.5);
}

.preset-row--selected {
    background-color: rgba(colors.$secondary, 0.2);
}