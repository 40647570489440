@use '../../../colors';
@use '../../../fonts';

.dialog.splice-plan-setup-dialog {
    .card-select {
        width: 100%;
    }

    .edit-text-container {
        width: 100%;
    }

    .spinner {
        display: flex;
        width: 100%;
        justify-content: center;
    }
}