@use "../../../colors";
@use "../../../fonts";

.upload-dialog-content {
    backdrop-filter: unset;
    -webkit-backdrop-filter: unset;
    background-color: #FBFCFA !important;  // custom color for drop area only
}

.drop-area {
    text-align: center;
    font: fonts.$caption;
    color: colors.$on-surface;
    width: 100%;
    height: 312px;

    &.dragging {
        border-color: colors.$secondary;
    }

    .file-selection-container {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 32px 0px;
        font: fonts.$subheader;
        font-size: 24px;

        p {
            padding: 10px;
        }

        button {
            margin: auto !important;
            padding: 10px;
        }

        input {
            display: none;
        }
    }

    .file-chip-set {
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;

        >div {
            background-color: rgba($color: #101010, $alpha: 0.12);
        }
    }


    .error-box {
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: colors.$error;

        .warning-icon {
            margin-right: 8px;
        }
    }
}