@use '../../../../../../colors';
@use '../../../../../../fonts';
@use '../../../../../../sizes';

.build-toolbar-container {
    .build-exported-toolbar {
        display: flex;
        flex-direction: row;
        align-items: center;

        .build-exported {
            padding-right: 10px;
            text-transform: uppercase;
            font: fonts.$caption;
            color: rgba($color: colors.$success, $alpha: 0.6);
        }

        .icon-button {
            color: colors.$on-surface;
        }
    }

    .build-toolbar {
        display: flex;
        flex-direction: row;
        align-items: center;

        .icon-button {
            color: colors.$on-surface;
        }

        .status-text {
            font: fonts.$statustext;
            text-transform: uppercase;
            opacity: 0.6;

            &.in-review {
                color: colors.$warning-selected;
            }
            &.rejected {
                color: colors.$error;
            }
            &.approved {
                color: colors.$success;
            }
        }

        .status-icon, .check-box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: sizes.$btn-square-width-default;
            height: sizes.$btn-square-width-default;

            &.in_review, &.wait_for_review, &.approved, &.stamped, &.uploaded {
                color: colors.$disabled-dark;
            }
        }

        .MuiCheckbox-root {
            padding: 6px;

            &.Mui-checked {
                color: colors.$secondary !important;
            }
        }
    }
}