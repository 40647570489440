@use "../../colors";
@use "../../fonts";

$login-background-color: rgba(255, 255, 255, 0.5);
$login-background-filter: blur(20px);

.login-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100%;
    background-image: url('./login_background.jpg');

    .login-header {
        min-height: 100px;
        max-height: 175px;
        background: $login-background-color;
        backdrop-filter: $login-background-filter;

        .corning-glass-logo {
            padding: 16px;
            height: 100%;
        }
    }

    .login-footer {
        display: flex;
        height: 30px;
        align-items: center;
        justify-content: center;
        background: $login-background-color;
        backdrop-filter: $login-background-filter;
    }

    .login-content {
        margin-top: 20px;
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        .app-label {
            color: white;
            margin: 8px;
        }

        .welcome {
            .hint {
                font: fonts.$caption;
            }
        }

        .login-widget {
            height: 340px;
            width: 300px;
            padding: 16px;
            background: rgba(255, 255, 255, 0.5);
            backdrop-filter: blur(20px);
            border-radius: 4px;

            .card-title {
                cursor: default !important;

                .card-subheader {
                    text-transform: none !important;
                }
            }

            form > div {
                width: 100%;
                margin-top: 16px;
                > input {
                    background-color: rgba(0, 0, 0, 0.4);
                }
                > label {
                    color: black;
                }
            }

            .trailing-icon {
                outline: none;
                ::-moz-focus-inner {
                    border: 0;
                }
            }

            .login-error {
                height: 15px;
            }

            .login-button {
                width: 100%;
                height: 48px;
                margin-top: 16px;
                border-radius: 30px;
                background: colors.$secondary;
                color: colors.$on-secondary;
            }
        }
    }

    .message {
        position: absolute;
        top: calc(50% - 48px);
        width: 100%;
        padding-top: 48px;
        padding-bottom: 48px;
        text-align: center;
        background-color: $login-background-color;
        backdrop-filter: $login-background-filter;
        color: white;
    }
}