@use "../../../colors";
@use "../../../fonts";

.presets-list {
    position: relative;
    display: flex;
    flex-basis: 40%;
    margin: 0px 8px 16px 16px;
    padding-right: 8px;

    .presets-container {
        width: 100%;

        .presets-tab-bar {
            position: relative;
            box-shadow: 0px 6px 6px -4px rgba(0, 0, 0, 0.16);
        }
    }

    .presets-list-container {
        height: calc(100% - 32px);
        overflow-y: auto;

        .presets-list-count {
            background-color: #ffffff;
            color: #000000;
            font: fonts.$title;
            padding: 8px 16px;
        }
    }

    .mdc-tab-indicator>.mdc-tab-indicator__content--underline {
        background-color: colors.$secondary;
    }

    .mdc-tab .mdc-tab__text-label {
        color: rgba(32, 32, 32, 0.7);
    }

    .mdc-tab--active .mdc-tab__text-label {
        color: rgba(32, 32, 32, 1);
    }

    .mdc-tab {
        flex: 0 0 auto;
    }

    .new-button {
        position: absolute;
        right: 7px;
        margin: 8px;
    }
}