@use '../../../colors';

$optical: #38BCB8;
$power: #EB8D64;
$hoveredBackdrop: #4E4E50;
$gray30: #757575;
$gray48: #7A7A7A;
$gray62: #9E9E9E;
$gray98: #FAFAFA;

.tether-svg {
    cursor: pointer;
}

.edit-icon {
    filter: url(#editIconShadow);
    path {
        fill: $gray98;
    }
    .optical {
        fill: colors.$secondary;
    }
    .power {
        fill: $power;
    }
}

.info-icon {
    circle {
        fill: $gray30;
        fill-opacity: 0.4;
    }
    text {
        font-family: Arial;
        font-size: 12px;
        fill: $gray98;
    }
}

.terminal-placeholder {
    .outline {
        fill: none;
        stroke: $gray62;
    }
    text {
        font-family: Arial;
        font-size: 12px;
        fill: $gray62;
    }
}

.tether {
    fill: none;
    &.optical {
        stroke: $optical;
    }
    &.power {
        stroke: $power;
    }
    .outline {
        stroke: $gray48;
        stroke-width: 4px;
    }
    .fill {
        stroke-width: 2px;
    }
}

.connector {
    stroke: $gray48;
    &.optical {
        fill: $optical;
    }
    &.power {
        fill: $power;
    }
    .fill {
        stroke: none;
    }
    .outline {
        fill: none;
    }
}

.index-label {
    font-family: TheSansC4s, Arial, sans-serif;
    font-size: 14px;
    fill: black;
    opacity: 0.87;
}

.backdrop {
    fill: transparent;
    &.hover {
        fill: $hoveredBackdrop;
        opacity: 0.04;
    }
    &.selected {
        fill: colors.$secondary;
        opacity: 0.2;
    }
}
