@use "../../colors";
@use "../../fonts";

.icon-close {
    margin-top: 16px !important;
}

.clickable:hover {
    cursor: pointer;
    opacity: 0.5;
}


.dialog.about-dialog {
    .tool-title {
        font-weight: lighter;
        font-size: 24px;
        margin-bottom: 10px;
    }

    .build-info {
        font-weight: lighter;
        font-size: 14px;
    }

    .thirdparty {
        background: none;
        border: none;
        text-transform: none !important;
        font-weight: lighter;
        font-size: 14px;
        color: colors.$secondary;
    }

    .dialog-header {
        align-items: center;

        .dialog-title {
            display: block;
        }
    }

    .dialog-content {
        padding: 0 !important;
        width: 400px;

        .brand-container {
            padding: 8px;
        }

        .brand {
            max-width: 100%;
        }
    }

    .about-footer.dialog-footer {
        display: block;
        justify-content: center;
        padding: 8px 0 5px 0 !important;
        width: 100%;

        hr {
            background: none;
            margin: 8px 0;
        }

        .text-footer {
            text-align: center;
            font: fonts.$caption;
            opacity: 0.6;
        }
    }
}
