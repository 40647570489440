@use '../../../../../fonts';
@use '../../../../../colors';

.workspace-drawer-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    min-height: 40px;
    max-height: 152px;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;

    .workspace-drawer-icon {
        .load-workspace-icon {
            cursor: pointer;
        }
    }

    &.loaded {
        background: rgba($color: colors.$secondary, $alpha: 0.3);
    }

    &:hover {
        background-color: rgba($color: colors.$on-secondary, $alpha: 0.8);
    }

    .workspace-drawer-row-end {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        padding-left: 16px;

        .workspace-drawer-short-desc-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .workspace-drawer-short-desc {
                display: flex;
                flex-direction: column;

                .workspace-drawer-workspace-name {
                    font: fonts.$menuheader;
                    color: rgba($color: colors.$on-surface, $alpha: 0.9);
                    padding-bottom: 5px;
                }

                .workspace-drawer-date-modified {
                    font: fonts.$caption;
                    color: rgba($color: colors.$drawer-on-primary, $alpha: 0.6);
                }
            }
        }
    }
}