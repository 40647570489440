$primary: #d7d2cb;
$secondary: #005293;
$surface: #e1f5fc;
$background: #efedeb;
$background2: #f3fcff;
$info: #0087C8;
$error: #c60c30;
$success: #009B3A;
$warning: #FF9800;
$warning-selected: #ca651d;
$warning-splice-plan: #e67022;

$on-primary: #202020;
$on-secondary: #fafafa;
$on-input: #FFFFFF;
$on-surface: #4e4e50;
$on-background: #404040;
$on-error: #fdfdfd;
$on-success: #ffffff;
$on-warning: #fdfdfd;
$on-surface-bg: rgba($on-surface, 0.08);
$on-primary-integrated: rgba($on-primary, 0.6);
$on-surface-integrated: rgba($on-surface, 0.6);

$drawer-on-primary: #191919;

$card-background: #e1f5fc;
$card-border: #7fcbdd;

$ripple: #e0e0e0;
$disabled: #9e9e9e;
$disabled-text: #757575;
$disabled-dark: #aaaaaa;

$disabled-background: rgba($disabled, 0.2);
$surface-bg: rgba($surface, 0.75);

$svg-disabled: #bdbdbd;
$svg-dark: #606060;
$svg-border: #9e9e9e;

// Button Background Colors
$hovered-item: rgba($secondary, 0.08);
$hovered-outlined-button: rgba($secondary, 0.4);
$hovered-button: rgba($disabled, 0.4);
$hovered-button-error: #8A0821;
$active-button: rgba($secondary, 0.2);
$active-button-dark: #005293;
$selected-item: rgba($secondary, 0.08);
$disabled-item: rgba($disabled, 0.8);

// Tube and fibers colors
$pipe-aqua: #03E8E9;
$pipe-black: #101010;
$pipe-blue: #1604E8;
$pipe-brown: #A83812;
$pipe-green: #01F936;
$pipe-orange: #FA8928;
$pipe-red: #FF001D;
$pipe-rose: #F485EA;
$pipe-slate: #656565;
$pipe-violet: #8901FA;
$pipe-white: #FAFAFA;
$pipe-yellow: #EEEE3B;
$pipe-copper: #EB8D64;
$pipe-copper-light: #F2D4C7;