@use '../../../../../../fonts';
@use '../../../../../../colors';

.design-area-row-container {
    .design-area-row {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        min-height: 40px;
        padding: 16px;

        &.selected {
            background: rgba($color: colors.$secondary, $alpha: 0.3);
        }

        &:hover {
            background-color: rgba($color: colors.$on-secondary, $alpha: 0.8);
        }

        .design-area-icon {
            .hover-design-area-icon-path {
                display: none;
                fill: colors.$on-surface;
            }

            &:hover {
                cursor: pointer;

                .design-area-icon-path {
                    display: none;
                }

                .hover-design-area-icon-path {
                    display: inline;
                }
            }

            &.canvas svg {
                cursor: default
            }
        }

        .design-area-row-end {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            padding-left: 16px;

            .design-area-info-container {
                display: flex;
                flex-direction: column;

                .design-area-name {
                    font: fonts.$subheader;
                    color: colors.$on-surface;
                    padding-bottom: 5px;
                }

                .design-area-location {
                    font: fonts.$caption;
                    color: rgba($color: colors.$drawer-on-primary, $alpha: 0.6);
                }
            }
        }
    }
}

.design-area-toolbar {
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon-button {
        color: colors.$on-surface;
    }
}