@use "../../colors";
@use "../../dna.scss";

.layers-menu {
    @extend .tool-overlay-container;
    
    user-select: none;
    position: relative;
    z-index: 2;
    border: none;
    margin-top: 16px;
    padding-top: 8px;
    border-radius: 4px;
    float: right;
    min-width: 240px;
    max-height: 650px;
    overflow: auto;

    .layers-menu-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 0px 8px 0px 16px;
    }

    li {
        display: flex;
        padding: 16px;
        cursor: pointer;
        align-items: center;

        img {
            padding-right: 16px;
            max-height: 24px;
        }

        &.group {
            font-weight: bolder;
            padding-left: 16px;
        }

        &.item {
            padding-left: 56px;
        }

        &:hover {
            background: colors.$hovered-item;
        }

        &:active {
            background: colors.$selected-item;
        }
    }
}