@use '../../../../colors';
@use '../../../../fonts';

.drawer-menu-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 72px;
    padding-left: 16px;
    padding-right: 16px;

    .drawer-menu-workspace-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        .drawer-menu-workspace-info-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            padding-left: 16px;

            .drawer-menu-workspace-name {
                font: fonts.$title;
                color: colors.$on-surface;
                padding-bottom: 4px;
            }

            .drawer-menu-show-workspaces {
                font: fonts.$body;
                color: colors.$secondary;
                background: none;
                text-transform: uppercase;
            }
        }
    }
}