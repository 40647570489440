@use '~toastr/toastr.scss';
@use '../fonts';
@use '../colors';

#toast-container {
    font: fonts.$body;

    .toast {
        opacity: 1;
        margin-top: 25px;
        min-width: 25%;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    button.toast-close-button {
        position: absolute;
        top: 0.2rem;
        right: 0.5em;
        font-size: 30px !important;
        font-weight: lighter !important;
    }

    .toast-message {
        padding-right: 36px;
    }

    .toast-error {
        background-image: url('../icons/toast_error.svg') !important;
        background-position-y: 0.75rem;
    }

    .toast-warning {
        background-image: url('../icons/toast_warning.svg') !important;
        background-position-y: 0.75rem;
    }

    .toast-info {
        background-image: url('../icons/toast_info.svg') !important;
        background-position-y: 0.75rem;
    }

    .toast-loading {
        background-image: url('../icons/toast_loading.svg') !important;
        background-color: colors.$card-background;
        background-position-y: 0.75rem;
        color: colors.$secondary;
        font: fonts.$toast;
    }

    .toast-success {
        background-image: url('../icons/toast_success.svg') !important;
        background-position-y: 0.75rem;
    }
}