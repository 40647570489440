@use "../../colors";
@use "../../fonts";

#gis-data-remove {
    margin-top: 1rem;
}

.remove-gis-data {
    min-height: 0;
    text-align: left;
    width: 460px;
    padding: 16px;

    .card-content {
        margin: 0;

        .MuiList-root {
            box-shadow: none !important;
            overflow: auto;
        }
    }

    .icon-delete {
        visibility: hidden;
        cursor: pointer;
    }

    .remove-gis-data-element {

        &:hover {
            .icon-delete {
                visibility: visible;
            }
        }

        &.disabled {
            div {
                color: colors.$disabled;
            }

            .icon-description {
                fill: colors.$disabled;
            }

            .icon-delete {
                visibility: hidden;
            }
        }
    }
}

.remove-gis-data-dialog {
    .content {
        padding-left: 0.5rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .message {
        font: fonts.$body;
        color: colors.$on-primary;
    }

    .negative-button {
        background-color: colors.$error !important;
        color: white !important;
    }
}