@use "../../colors";
@use "../../fonts";
@use "../../dna.scss";

.menu-history-container {
    display: flex;
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: 1;
    align-items: center;

    .controls-container {
        position: unset;
        left: unset;
    }

    .upload-build-container {
        margin-left: 1.5rem;
    }

    .history-container {
        @extend .tool-overlay-container;
        margin-left: 24px;
    }
}

.controls-container {
    @extend .tool-overlay-container;
    display: flex;
    position: absolute;
    left: 16px;
    z-index: 1;

    &.map-menu-container {
        top: 16px;
        height: 48px;
        padding-right: 16px;
        align-items: center;

        .title {
            margin-left: 8px;
            font: fonts.$title;
            color: colors.$on-surface;
        }

        .hamburger {
            height: 100%;
            width: 48px;
            border: none;
            cursor: pointer;
            padding: 2px;
            background: transparent;
        }
    }

    &.tools-control-container {
        flex-direction: column;
        position: absolute;
        width: 48px;
        top: 50%;
        transform: translateY(-50%);
        -ms-transform: translateY(-50%);

        .tool-item {
            width: auto !important;
        }
    }

    &.map-control-container {
        bottom: 16px;
    }
}

.menu-bar-container {
    position: absolute;
    z-index: 1;
    top: 16px;
    right: 16px;
    text-align: right;


    .menu-bar {
        @extend .tool-overlay-container;
        display: inline-flex;
    }

    .popup {
        position: relative;
        width: fit-content;
        min-width: 21rem;
        text-align: left;
        margin-top: 1rem;
        right: 2rem;
        overflow: auto;

        background-color: #005293;
        padding: 1em 1.5em 0em;
        font-size: 1em;
        color: #F5F5F5;
        border-radius: 4px;
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);

        &.single {
            padding: 1em 1.5em;
        }

        li {
            line-height: 1.5;
        }

        ul {
            padding: 0% 5%;
            width: 100%;
            margin: 0.625rem 0rem;

            &.single {
                padding: 0%;
                list-style-type: none;
            }

        }

        button {
            background-color: inherit;
            color: inherit;
            cursor: pointer;
            padding: 0.625rem;
            float: right;

            &:hover {
                background-color: #0098DB14;
            }
        }
    }

    .notch {
        position: absolute;
        top: 3.25rem;
        border-bottom-color: #005293;

        &.gis {
            left: 9.75rem;
        }

        &.canvas {
            left: 12.75rem;
        }
    }
}

.tool-divider {
    height: 1px;
    width: 24px;
    margin-left: 12px;
    margin-right: 12px;
    background-color: rgba(colors.$on-surface, 0.12);
}

.menu-divider {
    height: 32px;
    width: 2px;
    margin: 0 3px;
    background-color: rgba(colors.$on-surface, 0.2);
}

.app-icon {
    height: 16px;
    margin: 0 8px;
}

.app-name {
    font: normal normal 200 20px/24px TheSansC4s;
    color: colors.$secondary;
    margin: 0 8px;
}

.search {
    padding: 6px;
    margin-right: 8px;
    margin-left: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .search-btn {
        cursor: pointer;
        margin-right: -32px;
        z-index: 2;
    }

    .clear-btn {
        cursor: pointer;
        margin-left: -32px;
        z-index: 2;
    }

    .input-with-suggest {
        input {
            font-size: 1em;
            color: black;
            outline-color: transparent;
            height: 36px;
            width: 300px;
            border-radius: 4px;
            border: 1px solid rgba(colors.$secondary, 0.6);
            padding: 5px 5px 5px 36px;
        }

        #search-input {
            color: black;
            background-color: white !important;
        }
    }
}

.search-results {
    position: absolute;
    right: 0;
    width: 100%;
    color: colors.$on-surface;
    background: colors.$card-background;
    margin-top: 8px;
    text-align: left;
    overflow: auto;
    max-height: 56vh;
    border-radius: 4px;
    border: 1px solid colors.$card-border;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);

    .result {
        cursor: pointer;
        height: 54px;
        display: flex;
        align-items: center;
        padding: 0 16px;

        .number {
            width: 16px;
            text-align: right;
        }

        img {
            width: 24px;
        }

        span,
        img {
            margin-right: 8px;
            margin-left: 8px;
        }

        &:not(:last-child) {
            border-bottom: 1px solid #aaa;
        }

        &:hover {
            background-color: colors.$hovered-outlined-button;
        }
    }

    .selected {
        background-color: colors.$hovered-outlined-button;
    }

    .zero-results {
        color: colors.$error;
        font: fonts.$headline;

        span {
            font-weight: bold;
        }
    }
}