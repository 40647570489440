@use "../../colors";
@use "../../fonts";

.edit-text-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 8px 0;
    gap: 10px;

    .text-field-container {
        width: 100%;
        display: flex;
        flex-direction: column;

        .MuiInputBase-root {
            padding-right: 0;
        }

        &.with-units {
            input {
                padding-right: 10px;
            }
        }
    }

    .icon-button {
        margin-top: 8px !important;
        clip-path: none;
        border-radius: 4px;
    }
}

.helper-text-error {
    .MuiTypography-root {
        color: colors.$error  !important;
    }
}

.helper-counter {
    .MuiTypography-root {
        float: right;
    }
}

.helper-counter.max-characters {
    .MuiTypography-root {
        color: colors.$error  !important;
    }
}