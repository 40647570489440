@use "../../colors";
@use "../../fonts";

.tool-item-container {
    display: inline-flex;
    align-items: center;

    .tool-item {
        display: flex;
        width: 48px;
        height: 48px;
        justify-content: center;
        align-items: center;

        .icon-container {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 48px;
            height: 48px;
            border: none;
            background: transparent;
            cursor: pointer;
            line-height: 50%;

            &:hover {
                background: colors.$hovered-item;
            }

            &.active,
            &:active {
                background: colors.$selected-item;

                .badge {
                    opacity: 0.6;
                }
            }

            .badge {
                position: absolute;
                color: colors.$on-input;
                min-width: 20px;
                height: 20px;
                bottom: 4px;
                right: 0;
                border-radius: 50%;
                line-height: 1rem;
                animation: pulse 1.5s 1;
                display: flex;
                align-items: center;
                justify-content: center;
                
                &.notification {
                    background-color: #3ab387;
                }
                
                &.warning {
                    background-color: colors.$warning;
                }
                
                &-number {
                    animation: pulse 1.5s 1;
                    font-size: 0.75rem;
                    padding: 3px;
                    
                    &.warning:after {
                        content: " ";
                        position: absolute;
                        top: -2px;
                        left: -2px;
                        border: 2px solid rgba(colors.$warning, 0.5);
                        opacity: 0;
                        border-radius: 50%;
                        width: 100%;
                        height: 100%;
                        animation: sonar 1.5s 1;
                    }

                    &.notification:after {
                        content: " ";
                        position: absolute;
                        top: -2px;
                        left: -2px;
                        border: 2px solid rgba(#3ab387, 0.5);
                        opacity: 0;
                        border-radius: 50%;
                        width: 100%;
                        height: 100%;
                        animation: sonar 1.5s 1;
                    }
                }
            }
        }

        .icon-container:hover+.tool-item-tooltip {
            visibility: visible;
            width: 96px;
            margin-left: 8px;
            padding: 8px;
        }

        .tool-item-tooltip {
            visibility: hidden;
            display: flex;
            height: 24px;
            width: 0px;
            justify-content: space-between;
            align-items: center;
            border: none;
            border-radius: 4px;
            background: #101010;
            color: #E1F5FC;

            span {
                font: fonts.$caption;
            }
        }
    }

    &.disabled {
        opacity: 0.6;
        touch-action: none;
        pointer-events: none;
    }
}

@keyframes sonar {
    0% {
        transform: scale(0.9);
        opacity: 1;
    }

    100% {
        transform: scale(2);
        opacity: 0;
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    20% {
        transform: scale(1.4);
    }

    50% {
        transform: scale(0.9);
    }

    80% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}