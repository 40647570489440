@use '../../../fonts';
@use '../../../colors';

#port-mode-radio {
    margin-left: 1rem;
}

.mode-description {
    font: fonts.$body2;
    margin-top: 0;
}