.MuiDialogContent-root {
    padding: 0px 16px 16px 16px !important;

    .canvas-design-area-card {
        min-width: 457px;

        .input-container {
            display: flex;
            flex-direction: column;

            .orbit-select,
            .orbit-text-field-container {
                height: 71px;
            }
        }
    }
}