@use '../../colors';
@use '../../fonts';

#review-list {
    width: 30rem;

    .card-content {
        margin-left: 0;
        margin-right: 0;
    }

    button.MuiButtonBase-root.MuiTab-root {
        white-space: nowrap;
    }

    .requests {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        row-gap: 0.5rem;

        &.archive {
            margin: 0;
        }
    }

    .no-results {
        margin: 1rem 0.25rem 0.5rem 0rem;
    }

    .archive-controls {
        width: 100%;
        margin-top: 0.5rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .MuiInput-root {
            background-color: rgba(0, 0, 0, 0.06);
            border-radius: 2px;
            padding-top: 0.1rem;
        }

        .MuiButtonBase-root {
            border: none;
        }
    }

    .item {
        background-color: colors.$background2;
    }

    .request {
        padding: 0.5rem;

        &.hovered {
            background-color: rgba(colors.$secondary, 0.08);

            .response-buttons {
                visibility: visible;
            }
        }

        button {
            padding: 0.25rem;
            min-width: unset;
        }

        .title {
            padding-bottom: 0.25rem;
            border-bottom: 0.0625rem solid rgba(#000000, 0.12);
            margin-bottom: 0.25rem;
            font: fonts.$reviewtitle;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &.archive-tab {
                justify-content: flex-start;
                border: none;
            }

            &.resolved-tab {
                border: none;
            }

            .title-info {
                display: inherit;
                align-items: center;
            }

            .status {
                margin: 0rem 0.25rem 0rem 0.5rem;

                p {
                    font-size: 14px !important;
                }

                &.approved p {
                    color: colors.$success !important;
                }

                &.rejected p {
                    color: colors.$error !important;
                }
            }

        }

        #archive-review {
            border-bottom: 0.0625rem solid rgba(#000000, 0.12);
            padding-bottom: 0.25rem;
            margin-bottom: 0.25rem;
        }

        .actor-info {
            margin-bottom: 0.25rem;

            .submitter {
                margin: 0;
            }
        }

        .response-buttons {
            visibility: hidden;
            display: flex;
        }

        .submitter {
            margin-bottom: 1rem;
            font: fonts.$body2;

            &-archive {
                margin: 0.25rem 0rem;
            }
        }

        .message {
            font: fonts.$reviewmessage;
            margin: 0.25rem 0rem;

            &-resolved {
                font: fonts.$reviewmessage;
                margin: 0.25rem 0.2rem;
            }
        }
    }

    .responding {
        padding: 2rem 2rem 1rem 2rem;
    }

    .footer {
        margin-top: 1rem;
        display: flex;
        justify-content: flex-end;
        column-gap: 1rem;

        button {
            padding: 0.5rem;
        }
    }

    .resolved {
        background-color: colors.$success;
        color: colors.$on-success;
        padding: 0.5rem 1rem;

        button {
            color: colors.$on-success !important;
        }

        .status {
            font: fonts.$subheader;
            text-transform: uppercase;
        }

        .description {
            font: fonts.$body2;
        }
    }
}