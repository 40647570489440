@use '../../../../../colors';

.workspace-drawer-toolbar {
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon-button {
        color: colors.$on-surface;
    }
}