@use "../../colors";
@use "../../dna.scss";

.popup-menu {
    @extend .tool-overlay-container;
    position: relative;
    min-width: 220px;
    z-index: 2;
    border: none;
    margin-top: 10px;
    float: right;

    ul {
        padding: unset !important;
        margin: unset !important;
    }

    .menu-item {
        display: flex;
        padding: 15px;
        cursor: pointer;
        align-items: center;
        color: colors.$secondary;
        font-size: 0.875rem;
        line-height: 1em;

        &:hover {
            background: colors.$hovered-item;
        }

        &:active {
            background: colors.$selected-item;
        }

        &.item-divider {
            border-top: 1px solid rgba(#4E4E50, 0.2);
        }

        .menu-item-icon {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 36px;
        }
    }
}