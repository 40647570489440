#canvas-create-build-card .create-build-content {
    padding: 0px;
    margin: 0px;
}

.span-item {
    .inline-text-container {
        max-width: 5rem;
        width: auto !important;
    }
}