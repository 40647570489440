@use '../../../../../fonts';
@use '../../../../../colors';

.workspace-drawer-desc-container {
    display: flex;
    flex-direction: column;
    padding-top: 14px;

    .workspace-drawer-workspace-desc {
        font: fonts.$caption;
        color: rgba($color: colors.$drawer-on-primary, $alpha: 0.6);
        padding-bottom: 8px;
    }

    .workspace-drawer-workspace-location {
        font: fonts.$caption;
        color: rgba($color: colors.$drawer-on-primary, $alpha: 0.6);
        padding-bottom: 16px;
    }

    .workspace-drawer-workspace-info-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        .workspace-drawer-workspace-das {
            font: fonts.$caption;
            color: rgba($color: colors.$drawer-on-primary, $alpha: 0.85);
        }

        .workspace-drawer-workspace-builds {
            font: fonts.$caption;
            color: rgba($color: colors.$drawer-on-primary, $alpha: 0.85);
            padding-left: 16px;
            padding-right: 16px;
        }

        .workspace-drawer-workspace-exports {
            font: fonts.$caption;
            color: rgba($color: colors.$success, $alpha: 0.85);
        }
    }
}