@use './colors';
@use './fonts';
@use './sizes';
@use 'sass:math';

@import 'hybris-fixes.scss';
@import '~@orbit/text-field/build/index.css';


:root {
    --mdc-theme-primary: #{colors.$primary};
    --mdc-theme-secondary: #{colors.$secondary};
    --mdc-theme-surface: #{colors.$surface};
    --mdc-theme-background: #{colors.$background};

    --mdc-theme-on-primary: #{colors.$on-primary};
    --mdc-theme-on-secondary: #{colors.$on-secondary};
    --mdc-theme-on-surface: #{colors.$on-surface};

    --mdc-theme-error: #{colors.$error};
}

.app-root {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: TheSansC4s, Arial, Helvetica, sans-serif !important;

    .design-container {
        position: relative;
        width: 100%;
    }

    &-design {
        background: url('./components/design-mode/vc-background.png') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        height: 100%;
        width: 100%;
    }
}

body {
    line-height: 1.4;

    &.page-designConfig {
        overflow: initial;
    }

    margin: 0px !important;
}

html,
body,
#react-app-root {
    height: 100%;
}

.app-root {
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
}

div.design-container {
    height: 100%;
}

.map-topbar+div.design-container {
    height: calc(100% - 50px);
}

a {
    color: #333333;
    text-decoration: none;
}

img {
    user-select: none;
}

::-webkit-scrollbar {
    width: 6px;
}

* {
    // firefox scrollbar styling
    scrollbar-width: thin;
    scrollbar-color: rgba(colors.$secondary, 0.8) transparent;
}

::-webkit-scrollbar-track {
    border-radius: 8px;
}

::-webkit-scrollbar-track-piece {
    background-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
    background: rgba(colors.$secondary, 0.8);
    border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(colors.$secondary, 0.9);
}

.devmsg {
    display: none;
    font-weight: 100;
    font-size: small;
    color: gray;
    font-family: monospace;
    font-style: italic;
}

.headline {
    font: fonts.$headline;
    padding-left: unset;
    margin: unset;
    background-color: unset;
}

.title {
    font: fonts.$title;
}

.subheader {
    font: fonts.$subheader;
}

.menuheader {
    font: fonts.$menuheader;
}

.menu {
    font: fonts.$menubody;
}

.body {
    font: fonts.$body;
}

.caption {
    font: fonts.$caption;
}

.error-message {
    color: colors.$error;
}

button {
    font: fonts.$button !important;
    text-transform: uppercase !important;
    outline: none;

    ::-moz-focus-inner {
        border: 0;
    }
}

.icon-button {
    background: rgba(255, 255, 255, 0);
    color: colors.$secondary;
    display: flex;
    align-items: center;
    justify-content: center;
    float: right;
    height: sizes.$btn-square-width-default;
    width: sizes.$btn-square-width-default;
    border: none;
    vertical-align: middle;
    clip-path: circle(math.div(sizes.$btn-square-width-default, 2) at center);
    cursor: pointer;

    &:hover {
        background: rgba(158, 158, 158, 0.5);
        color: colors.$secondary;
    }

    &:disabled {
        cursor: auto;
        background: transparent !important;
        opacity: 0.5;
    }
}

.mdc-text-field {
    input {
        font-family: TheSansC4s !important;
        color: colors.$on-surface;
        background-color: rgba(225, 245, 252, 1);
    }

    input:disabled {
        color: colors.$on-surface;
        background-color: rgba(224, 224, 224, 0.5);
    }

    &:not(.mdc-text-field--invalid) {
        .mdc-text-field__input {
            caret-color: colors.$secondary !important;
        }

        .mdc-line-ripple {
            background-color: colors.$secondary;
        }
    }

}

.mdc-floating-label {
    font-family: TheSansC4s;
}

.mdc-text-field--focused:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) .mdc-floating-label {
    color: colors.$secondary;
    font-family: TheSansC4s;
}

.mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea):not(.mdc-text-field--invalid) .mdc-text-field__input:hover {
    border-bottom-color: colors.$secondary;
}

.mdc-select {
    select {
        color: colors.$on-surface;
    }

    &:not(.mdc-select--disabled).mdc-select--focused .mdc-floating-label {
        color: colors.$secondary;
        font-family: TheSansC4s;
    }

    &:not(.mdc-select--disabled):hover .mdc-floating-label {
        color: colors.$secondary;
        font-family: TheSansC4s;
    }
}

.mdc-select:not(.mdc-select--disabled) .mdc-select__native-control:hover,
.mdc-select:not(.mdc-select--disabled) .mdc-select__selected-text {
    border-bottom-color: colors.$secondary;
}

.mdc-button:not(:disabled) {
    color: colors.$secondary;

    &.negative:not(:disabled) {
        color: colors.$error;
    }

    &:hover {
        background-color: colors.$hovered-button;
    }
}

button:not(:disabled) {
    &.negative:not(:disabled) {
        color: colors.$error;

        &.MuiButton-contained {
            color: colors.$on-secondary;
            background-color: colors.$error;

            &:hover {
                background-color: colors.$hovered-button-error;
            }
        }
    }
}

.mdc-button--outlined:not(:disabled) {
    border-color: colors.$secondary;
}

.mdc-button--outlined:hover {
    background-color: rgba(colors.$secondary, 0.2) !important;
}

.checkbox-disabled {
    color: rgba(0, 0, 0, 0.26) !important;
}

.checkbox-checked {
    color: colors.$secondary !important;
}

.MuiCheckbox-root {
    &.Mui-checked {
        color: colors.$secondary !important;
    }
}

ul.MuiList-root {
    @extend .tool-overlay-container;   
    padding: 0;
    
    li.MuiMenuItem-root {
        text-transform: uppercase;
        color: colors.$active-button-dark;
        padding: 15px;

        &:hover {
            background-color: colors.$hovered-item;
        }
    }
}

.tool-overlay-container {
    border-radius: 4px;
    background-color: colors.$card-background;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
    overflow: hidden;

    .allow-overflow {
        overflow: visible;
    }
}
