@use '../../../colors';

.splice-point-diagram {
    .container {
        width: 352px;
        height: 352px;
        display: grid;
        grid-template: 1fr / 1fr;

        svg, .splice-point-empty {
            grid-column: 1 / 1;
            grid-row: 1 / 1;
            width: 100%;
            height: 100%;
        }

        svg {
            background: transparent linear-gradient(180deg, colors.$card-background 0%, #7FD4F7 100%) 0% 0% no-repeat padding-box;
            fill: colors.$svg-dark;
            overflow: visible;
    
            text {
                opacity: 0.6;
                stroke-width: 0;
                fill: white;
                text-anchor: middle;
                font-family: Arial;
            }
            .CO-splice{
                fill: colors.$on-surface;
                opacity: 0.8;
                font: normal normal bold 14px/20px TheSansC4s;
            }
    
            .cable {
                stroke: colors.$svg-dark;
            }
    
            .connector {
                stroke: colors.$on-secondary;
                stroke-width: 2px;
                opacity: 0.6;
                fill: none;
            }
    
            .location-number {
                .location {
                    fill: #4A4A4A;
                    stroke: #707070;
                    stroke-width: 1px;
                }
                text {
                    fill: colors.$on-secondary;
                    font-size: 10px;
                    font-family: TheSansC4s, Arial, sans-serif;
                    opacity: 0.6;
                }
            }
    
            .closure {
                color: colors.$on-secondary;
                fill: colors.$svg-disabled;
                stroke: none;
                text-transform: uppercase;

                &.connected {
                    fill: colors.$svg-dark;
                    stroke: colors.$svg-border;
                }
            }

            .splice-cable {
                fill: none;

                .border {
                    stroke: colors.$svg-border;
                    stroke-width:4px;
                }

                .fill {
                    stroke: colors.$svg-dark;
                    stroke-width:2px;
                }

                .build-box {
                    fill: colors.$svg-dark;
                    stroke: colors.$svg-border;
                    stroke-width: 1px;
                }
            }
        }

        .splice-point-empty {
            display: flex;
            align-items: center;
            justify-content: center;

            .text {
                width: 237px;
                color: colors.$on-surface;

                .title {
                    text-transform: uppercase;
                    font-weight: bold;
                    line-height: 30px;
                }

                .description {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                }
            }
        }
    }
}

