.select-with-help {
    display: flex;
    align-items: center !important;
    margin-bottom: 16px;
    gap: 10px;

    div:first-child {
        width: 100%;
    }

    .icon-button {
        padding: 0px;
        margin: 0px 0px 0px 8px;
        height: 36px;
        width: 36px;
        clip-path: none;
        border-radius: 4px;
    }
}