@use '../../../fonts';
@use '../../../colors';

.dialog.workspace-delete {
    .dialog-header {
        width: 0;
        height: 0;
        padding: 0;
    }

    .dialog-content {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        width: 300px;
        min-width: 300px;
    }

    .workspace-delete-container {
        .workspace-delete-message-info {
            padding-top: 16px;
            padding-left: 16px;
            padding-right: 16px;
            font: fonts.$body;
            color: colors.$on-surface;
        }

        .workspace-delete-message-container {
            display: flex;
            flex-direction: row;
            padding-top: 8px;
            padding-left: 16px;
            padding-right: 16px;
            font: fonts.$body;
            color: colors.$on-surface;

            .workspace-message-workspace-name {
                padding-left: 4px;
                font: fonts.$menubody;
                color: colors.$on-surface;
            }
        }
    }
}