@use "../../../colors";
@use "../../../fonts";

.snackbar-container {
    .generic-snackbar {
    
        .mdc-snackbar__label {
            font: fonts.$menubody;
        }
    
        .mdc-snackbar__surface {
            color: colors.$on-success;
    
            .mdc-snackbar__actions {
                button {
                    .mdc-button__label {
                        color: colors.$on-success;
                    }

                    &.generic-snackbar-close-button {
                        min-width: unset;
                        height: 36px;
                        width: 36px;
    
                        .mdc-button__label {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }

                .generic-snackbar-action-button-group {
                    margin: 6px;
                    
                    .button-group-button {
                        background-color: colors.$secondary;
                        border: 0;
                        border-right: 1px solid colors.$active-button-dark;
                        color: colors.$on-input;
                        font-weight: 700 !important;
                        min-width: 100px;
                        padding: 8px;

                        &:last-child {
                            border: 0;
                        }
                        
                        &:hover {
                            background-color: colors.$info;
                        }
                        
                        &:disabled {
                            opacity: 1;
                            // border: 1px solid colors.$secondary;
                            color: colors.$on-input !important;
                            background-color: colors.$active-button-dark !important;
                        }

                    }
                }
            }
        }
    
        &.info {
            .mdc-snackbar__surface {
                background-color: colors.$info;
            }
        }
    
        &.error {
            .mdc-snackbar__surface {
                background-color: colors.$error;
            }
        }
    
        &.warning {
            .mdc-snackbar__surface {
                background-color: colors.$warning;
            }
        }
    
        &.success {
            .mdc-snackbar__surface {
                background-color: colors.$success;
            }
        }

        &.selection {
            .mdc-snackbar__surface {
                background: rgba(colors.$card-background, 0.5);
                backdrop-filter: blur(15px);
            }

            .generic-snackbar-tip {
                color: colors.$on-primary-integrated;
                font-size: 12px;
                font-weight: 300;

                strong {
                    font-weight: 600;
                }
            }
            
            .mdc-snackbar__label {
                display: none;
            }
        }

        .snackbar-progress {
            overflow-x: hidden;
            height: 4px;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            border-radius: 0 0 4px 4px;

            .bar {
                height: 4px;
                background-color: rgba(colors.$on-secondary, 0.4);
                position: relative;

                .slider {
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    will-change: left, right;
                    background-color: colors.$on-secondary;
                    animation: snackbar-slide 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
                }
            }

            @keyframes snackbar-slide {
                0% {
                    left: -35%;
                    right: 100%;
                }
                60% {
                    left: 100%;
                    right: -90%;
                }
                100% {
                    left: 100%;
                    right: -90%;
                }
            }
        }
    }
}