@use "../../colors";
@use "../../fonts";
@use "../../dna.scss";

.context-menu {
    @extend .tool-overlay-container;
    display: flex;
    flex-direction: column;
    width: 160px;
    position: fixed;
    border: none;

    &.opaque {
        z-index: 1000;
        width: fit-content;
        
        .context-menu-option-name {
            white-space: nowrap;
            padding-right: 16px;
        }
    }

    .context-menu-container {
        display: flex;
        flex-direction: column;
        padding-top: 8px;
        padding-bottom: 8px;

        .context-menu-title {
            padding: 8px 16px;
            font: fonts.$subheader;
            color: black;
        }

        .context-menu-options-list {
            display: flex;
            flex-direction: column;

            .context-menu-option {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                height: 24px;
                padding-top: 8px;
                padding-bottom: 8px;
                cursor: pointer;

                .context-menu-option-name-container {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding-left: 16px;

                    .context-menu-option-color {
                        height: 20px;
                        width: 20px;
                        border: 1px solid #101010;
                        border-radius: 2px;

                        &.strikethrough {
                            background-image: linear-gradient(to bottom right, transparent 0 49%, #101010 49% 51%, transparent 51% 100%);
                        }
                    }

                    .context-menu-option-start-icon {
                        height: 24px;
                        width: 24px;
                    }

                    .context-menu-option-name {
                        padding-left: 8px;
                        font: fonts.$menubody;
                        color: colors.$on-surface;
                    }
                }

                .context-menu-option-end-icon {
                    padding-right: 16px;
                }

                .context-menu-option-shortcut {
                    padding-right: 16px;
                    font: fonts.$subsubheader;
                    color: rgba($color: colors.$on-surface, $alpha: 0.8);
                }

                &:hover {
                    background: colors.$hovered-item;
                }

                &.disabled,
                &.selected {
                    pointer-events: none;
                }

                &.disabled {
                    .context-menu-option-color {
                        opacity: 0.2;
                    }

                    .context-menu-option-name {
                        color: colors.$disabled-dark;
                    }
                }

                &.selected {
                    background-color: rgba($color: colors.$secondary, $alpha: 0.2);
                }
            }
        }

        .context-menu-tips-container {
            display: flex;
            align-items: center;
            padding: 10px 5px 0px 5px;

            .context-menu-tips {
                border-top: solid 1px #757575;
                padding-top: 10px;
                font: fonts.$subsubheader;
            }
        }
    }

    &.build-selection {
        .context-menu-option {
            &:hover {
                .context-menu-option-end-icon {
                    i {
                        visibility: visible;
                    }
                }
            }
        }

        .context-menu-option-end-icon {
            i {
                visibility: hidden;
                color: colors.$secondary;
            }

            &.icon-button {
                padding-right: 0 !important;
                margin-right: 8px !important;
            }
        }
    }

    &.modification {
        &.delete {
            .context-menu-option-start-icon {
                i {
                    color: colors.$error;
                }

                .icons-disable {
                    color: colors.$disabled-text;
                }
            }

            .context-menu-option-name {
                color: rgba($color: colors.$error, $alpha: 0.85) !important;
                
                &.option-name-disable {
                    color: rgba($color: colors.$disabled-text, $alpha: 1) !important;
                }
            }

        }

        &:focus {
            outline: none;
        }
    }
}