@use '../../../colors';
@use '../../../fonts';

#cabinet-splice-plan {
    padding-top: 1.8rem;
    padding-left: 1.25rem;
    width: 40rem;

    .card-header {
        margin-bottom: 0.5rem;
        margin-left: 0.25rem;
    }

    .splice-plan-setup {
        display: flex;
        flex-direction: row;
        column-gap: 1rem;
        padding-top: 0.5rem;
        padding-bottom: 1rem;
        font-size: 0.875rem;
        opacity: 0.87;

        .settings-button {
            display: flex;
            flex-direction: row;
            align-items: center;

            .material-icons {
                padding-right: 0.25rem;
            }
        }

        &-item {
            height: 36px;
            padding-right: 1rem;
            border-right: 0.0625rem solid rgba(colors.$on-surface, 0.4);

            &:last-child {
                border: none;
            }

            p {
                padding-top: 0.5rem;
            }
        }
    }

    .MuiTableCell-head {
        padding: 0;
        padding-bottom: 0.5rem;
        border-bottom: 0.0625rem solid rgba(colors.$on-surface, 0.4);
        font-weight: bold;
        color: rgba(colors.$on-surface, 0.6);
    }

    .MuiTableCell-body {
        padding: 0.5rem 0.25rem;
        border-bottom: 0;
        color: rgba(colors.$on-surface, 0.85);
    }

    .cable-id-cell {
        display: flex;
        flex-direction: row;
    }

    .cable-id-button {
        cursor: pointer;
        color: colors.$secondary;
        font-weight: 300 !important;
        text-transform: none !important;
        background-color: inherit;

        &:hover {
            text-decoration: underline;
        }
    }

    .indent-1 {
        padding-left: 0.25rem;
    }

    .indent-2 {
        padding-left: 1.75rem;
    }

    .indent-3 {
        padding-left: 3.25rem;
    }

    .spinner-container {
        display: flex;
        justify-content: center;
    }

    .port-range {
        cursor: pointer;
        color: colors.$secondary;

        &:hover {
            text-decoration-line: underline;
        }
    }

    .overlap {
        color: rgba(colors.$on-input, 0.85);
        background-color: colors.$warning-splice-plan;
        width: fit-content;
        padding: 0 0.25rem;
        border-radius: 0.25rem;
    }

    .order-arrows {
        display: flex;
        flex-direction: row;
        align-items: center;
        visibility: hidden;
        justify-content: flex-start;
        height: 0px;
    }

    .MuiTableCell-root:hover .order-arrows {
        visibility: visible;
    }

    .warning {
        margin-top: 1.5rem;
        color: rgba(colors.$on-input, 0.85);
        background-color: colors.$warning-splice-plan;
        padding: 0.75rem 1.25rem;
        border-radius: 0.25rem;
        display: flex;
        align-items: center;
        font-size: 0.875rem;

        .material-icons {
            margin-right: 2rem;
        }
    }
}