@use "../../colors";
@use "../../fonts";

.notifications {
    min-height: 0;
    text-align: left;
    margin-bottom: 1rem;
    padding: 1rem;
    .card-controls {
        margin-right: 1rem;
    }
    .card-content {
        margin: 0;
     }
    .card-header {
        .card-subheader {
            label{
                font: fonts.$body;
                margin-top: 0.5rem;
                margin-bottom: 0.5rem;
                opacity: 0.7;
                text-transform: none;
            }     
        }
    }
}

.notification-filters {
    display: flex;
    margin: 0.5rem 0;
    label {
        font-size: 0.875rem;
        color: colors.$on-surface;
        opacity: 0.8;
    }
}

.notification-elements {
    border-top: 2px solid rgba(colors.$on-surface, 0.4);
}

.notification-element {
    width: 100%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid rgba(colors.$on-surface, 0.2);
    margin-top: 0.5rem;
    cursor: pointer;
    &:hover {
        background-color: rgba(colors.$on-primary, 0.12);
    }
    &.error {
        .title {
            font-weight: bold;
        }
        .title, .short-description {
            color: colors.$error;
            font-size: 1rem;
        }
    }
    &.warning {
        .title {
            font-weight: bold;
        }
        .title, .short-description {
            color: colors.$warning; 
            font-size: 1rem;
        }
    }
    .icon-cell {
        img {
            margin-right: 0.25rem;
            width: 1.5rem;
            vertical-align: middle;
        }
    }
    .title {
        width: 100%;
    }
    .long-description {
        font-size: 0.875rem;
    }
}
