@use '../../../../colors';
@use '../../../../fonts';

.workspace-drawer-subheader {
    display: flex;
    flex-direction: column;
    height: 104px;
    padding-left: 16px;
    padding-right: 16px;

    .workspace-drawer-subheader-title-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-top: 12px;
        padding-bottom: 8px;

        .workspace-drawer-subheader-nb-workspaces {
            font: fonts.$title;
            color: rgba($color: colors.$on-surface, $alpha: 0.8);
        }

        .workspace-drawer-subheader-new-workspace {
            background: colors.$secondary;
            color: colors.$on-secondary;

            &:hover {
                background: rgba($color: colors.$secondary, $alpha: 0.8);
            }
        }
    }

    .workspace-drawer-subheader-filter-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: 0.3rem;

        .workspace-drawer-subheader-workspace-filter {
            .edit-text-container {
                margin: 0;
                width: 90%;

                .MuiInputLabel-root {
                    font-size: 0.8rem;
                    padding-top: 0.2rem;
                }
            }
        }

        .workspace-drawer-subheader-workspace-filter-options {
            label {
                span {
                    font: fonts.$body;
                    white-space: nowrap;
                }
            }
        }
    }
}