@use "../../colors";
@use "../../fonts";

.card-error-message {
    display: flex;
    padding-bottom: 8px;
    font: fonts.$caption;
    color: colors.$error;

    &-icon {
        padding-right: 8px;
    }
}